import { useEffect } from "react";
import Footer from "./components/Footer";
import Banner from "./components/Banner";
import Header from "./components/Header";

function Corporations() {
  useEffect(() => {
    document.title = "Claverton 42 - Corporations";
  }, []);

  return (
    <div id="wrapper">
      <Header />
      <div id="content">
        <Banner />

        <h2>Corporations</h2>

        <p>
          <span className="emphasis">You</span> have a product, department or
          broader problem and you are seeking external expertise to confirm,
          augment or reject your current thinking.
        </p>

        <p>Contact us to talk about how we can help.</p>

        <br />
        <p class="text-center">
          <a href="mailto:corporations@claverton42.com" className="button">
            Contact Us
          </a>
        </p>
      </div>

      <Footer />
    </div>
  );
}

export default Corporations;
