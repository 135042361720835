import { Link } from "react-router-dom";

function Banner() {
  return (
    <div id="banner">
      <Link to="/">
        <h1>Claverton 42</h1>
      </Link>
    </div>
  );
}

export default Banner;
