import { useEffect } from "react";
import Footer from "./components/Footer";
import Banner from "./components/Banner";
import Header from "./components/Header";

function Application() {
  useEffect(() => {
    document.title = "Claverton 42 - Application";
  }, []);

  return (
    <div id="wrapper">
      <Header />
      <div id="content">
        <Banner />

        <h2>Application</h2>

        <p>
          All information you submit will be held in the strictest confidence.
          You do not have to share any information you feel is too sensitive
          with us, but it may limit our ability to understand the needs of your
          business.
        </p>

        <p>
          We may ask for redacted reports to be made available for our
          portfolio, but there is no obligation on you to accept that request.
        </p>

        <iframe
          title="Application"
          src="https://docs.google.com/forms/d/e/1FAIpQLSeQbUHX-7SaL22W_QStDA6OfOnVFufOTqilJqIZrmi61D4yoQ/viewform?embedded=true"
          width="100%"
          height="2400"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading…
        </iframe>
      </div>

      <Footer />
    </div>
  );
}

export default Application;
