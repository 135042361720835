import { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "./components/Footer";
import Banner from "./components/Banner";
import Header from "./components/Header";

function SMEs() {
  useEffect(() => {
    document.title = "Claverton 42 - Small and Medium Enterprises";
  }, []);

  return (
    <div id="wrapper">
      <Header />

      <div id="content">
        <Banner />

        <h2>Small and Medium Enterprises</h2>

        <p>
          <span className="emphasis">You</span> are an established business with
          fewer than 250 employees.
        </p>

        <p>
          <span className="emphasis">We</span> will prepare a report unique to
          your business covering:
          <ul>
            <li>
              External Analysis - looking at the wider market, competitor
              landscape, political and legal factors
            </li>
            <li>
              Internal Analysis - core competencies, strengths, value chain,
              financial position
            </li>
            <li>
              Strategic Objectives and Initiatives - growth and operational
              strategies, risks and objectives
            </li>
            <li>
              Implementation Plan and Performance Measurements - tangible action
              plan and how to monitor it to achieve success
            </li>
          </ul>
        </p>

        <p>Standard report cost is £10,575.</p>

        {/* <p>
          You can see an example report within our portfolio{" "}
          <a href="#">here</a>.
        </p> */}

        <br />
        <p class="text-center">
          <Link class="button" to="/application">
            Apply
          </Link>
        </p>
      </div>

      <Footer />
    </div>
  );
}

export default SMEs;
