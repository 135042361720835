import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Startups from "./Startups";
import SMEs from "./SMEs";
import Corporations from "./Corporations";
import Reports from "./Reports";
import Portfolio from "./Portfolio";
import Careers from "./Careers";
import Cookies from "./Cookies";
import Privacy from "./Privacy";
import Application from "./Application";
import ScrollToTop from "./components/ScrollToTop";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/startups" element={<Startups />} />
        <Route path="/smes" element={<SMEs />} />
        <Route path="/corporations" element={<Corporations />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/application" element={<Application />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const w = window;
setTimeout(function () {
  w.scrollTo(0, 0);

  setTimeout(function () {
    w.scrollTo(0, 0);
  }, 900);
}, 100);
