import { useEffect } from "react";
import Footer from "./components/Footer";
import Banner from "./components/Banner";
import Header from "./components/Header";

function Portfolio() {
  useEffect(() => {
    document.title = "Claverton 42 - Portfolio";
  }, []);

  return (
    <div id="wrapper">
      <Header />

      <div id="content">
        <Banner />

        <h2>Portfolio</h2>

        <section>
          <div>
            <img
              src="/img/pexels-photo-5794059.avif"
              alt="Physiotherapy Practice"
            />
          </div>
          <div>
            <h2>A Physiotherapy Practice</h2>

            <p>
              The report for this physiotherapy practice highlights a strategy
              to pursue a targeted marketing approach towards PMLD day centres,
              a niche where this practice is already providing fantastic value
              for its clients.
            </p>

            <p>
              <a href="/portfolio/a-physiotherapy-practice.pdf" target="_blank">
                Read the report.
              </a>
            </p>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
}

export default Portfolio;
