import "./App.css";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "./components/Footer";
import Banner from "./components/Banner";
import Header from "./components/Header";

function App() {
  useEffect(() => {
    document.title = "Claverton 42 - Strategic clarity, tangible success";
  }, []);

  return (
    <div id="wrapper">
      <Header />

      <div id="content">
        <Banner />

        <p>
          We help forward-looking, ambitious companies define a strategic
          approach to their vision of success by offering an experienced,
          external perspective. We want you to be confident with the direction
          you are heading in.
        </p>

        <div className="links">
          <Link to="/startups">STARTUPS</Link>
          <Link to="/smes">SMEs</Link>
          <Link to="/corporations">CORPORATIONS</Link>
        </div>

        <section>
          <div>
            <img src="/img/pexels-photo-1687093.avif" alt="Market Research" />
          </div>
          <div>
            <h2>Market Research</h2>
            <p>
              You know your business better than anyone. But we want to
              understand its place in the market and so we do our research to
              ensure we understand the context before we look at any strategic
              direction.
            </p>
          </div>
        </section>

        <section>
          <div>
            <h2>Strategic Planning</h2>
            <p>
              Some businesses grow organically, but we want better for our
              clients. They have great strengths which the world is crying out
              for.
            </p>
            <p>Together, we need to deliver on that.</p>
          </div>
          <div>
            <img
              src="/img/pexels-photo-7654439.avif"
              alt="Strategic Planning"
            />
          </div>
        </section>

        <section>
          <div>
            <img
              src="/img/pexels-photo-3850910.avif"
              alt="Operational Improvement"
            />
          </div>
          <div>
            <h2>Operational Improvement</h2>
            <p>
              Operation improvement is often key to competitive advantage and
              long-term success. We can help identify inefficiences and areas
              for enhancement to propel growth with a tangible, actionable
              roadmap.
            </p>
          </div>
        </section>

        <section>
          <div>
            <h2>Digital Transformation</h2>
            <p>
              Today, digital is essential for almost every business. Our reports
              seek to pinpoint areas for improvements and identify new
              technologies to drive efficiencies, engagement and sales.
            </p>
          </div>
          <div>
            <img
              src="/img/pexels-photo-933964.avif"
              alt="Digital Transformation"
            />
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
}

export default App;
