function Header() {
  return (
    <div id="loader">
      <header>
        <h1>Claverton 42</h1>

        <h3 style={{ fontSize: "1.2rem" }}>for another perspective</h3>
      </header>
    </div>
  );
}

export default Header;
