import { useEffect } from "react";
import Footer from "./components/Footer";
import Banner from "./components/Banner";
import Header from "./components/Header";

function Privacy() {
  useEffect(() => {
    document.title = "Claverton 42 - Privacy Notice";
  }, []);

  return (
    <div id="wrapper">
      <Header />

      <div id="content">
        <Banner />

        <p>
          <h2>Customer privacy notice</h2>
          <p>
            This privacy notice tells you what to expect us to do with your
            personal information.
          </p>

          <h2>Contact details</h2>
          <p>
            Email:{" "}
            <a href="mailto:privacy@claverton42.com">privacy@claverton42.com</a>
          </p>

          <h2>What information we collect, use, and why</h2>
          <p>
            We collect or use the following information to provide services and
            goods, including delivery:
          </p>
          <ul>
            <li>Names and contact details</li>
          </ul>
          <p>
            We collect or use the following information for the operation of
            customer accounts and guarantees:
          </p>
          <ul>
            <li>Names and contact details</li>
          </ul>
          <p>
            We collect or use the following information for service updates or
            marketing purposes:
          </p>
          <ul>
            <li>Names and contact details</li>
            <li>Marketing preferences</li>
          </ul>

          <h2>Lawful bases</h2>
          <p>
            Our lawful bases for collecting or using personal information to
            provide services and goods are:
          </p>

          <ul>
            <li>Consent</li>
            <li>Contract</li>
          </ul>
          <p>
            Our lawful bases for collecting or using personal information for
            the operation of customer accounts and guarantees are:
          </p>
          <ul>
            <li>Consent</li>
            <li>Contract</li>
          </ul>
          <p>
            Our lawful bases for collecting or using personal information for
            service updates or marketing purposes are:
          </p>
          <ul>
            <li>Consent</li>
            <li>Contract</li>
          </ul>

          <h2>Where we get personal information from</h2>
          <p>Customers directly.</p>

          <h2>How long we keep information</h2>
          <p>12 months.</p>

          <h2>Sharing information outside the UK</h2>
          <p>
            Where necessary (for cloud storage) we may transfer personal
            information outside of the UK. When doing so, we comply with the UK
            GDPR, making sure appropriate safeguards are in place. Please
            contact us for more information.
          </p>

          <h2>Your data protection rights</h2>
          <p>Under data protection law, you have rights including:</p>

          <p>
            Your right of access - You have the right to ask us for copies of
            your personal data.
          </p>

          <p>
            Your right to rectification - You have the right to ask us to
            rectify personal data you think is inaccurate. You also have the
            right to ask us to complete information you think is incomplete.
          </p>

          <p>
            Your right to erasure - You have the right to ask us to erase your
            personal data in certain circumstances.
          </p>

          <p>
            Your right to restriction of processing - You have the right to ask
            us to restrict the processing of your personal data in certain
            circumstances.
          </p>

          <p>
            Your right to object to processing - You have the right to object to
            the processing of your personal data in certain circumstances.
          </p>

          <p>
            Your right to data portability - You have the right to ask that we
            transfer the personal data you gave us to another organisation, or
            to you, in certain circumstances.
          </p>

          <p>
            Your right to withdraw consent – When we use consent as our lawful
            basis you have the right to withdraw your consent.
          </p>

          <p>
            You don’t usually need to pay a fee to exercise your rights. If you
            make a request, we have one calendar month to respond to you.
          </p>

          <p>
            To make a data protection rights request, please contact us using
            the contact details at the top of this privacy notice.
          </p>

          <h2>How to complain</h2>
          <p>
            If you have any concerns about our use of your personal data, you
            can make a complaint to us using the contact details at the top of
            this privacy notice.
          </p>

          <p>
            If you remain unhappy with how we’ve used your data after raising a
            complaint with us, you can also complain to the ICO.
          </p>
        </p>
      </div>

      <Footer />
    </div>
  );
}

export default Privacy;
