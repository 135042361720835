import { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "./components/Footer";
import Banner from "./components/Banner";
import Header from "./components/Header";

function Startups() {
  useEffect(() => {
    document.title = "Claverton 42 - Startups";
  }, []);

  return (
    <div id="wrapper">
      <Header />
      <div id="content">
        <Banner />

        <h2>Startups</h2>

        <p>
          <span className="emphasis">You</span> have a business idea you have
          been working on for less than five years and with fewer than 10
          employees.
        </p>

        <p>
          <span className="emphasis">We</span> will choose startup submissions
          we believe we can add most value to and prepare a short report
          covering an overview of the market, current business model and our
          advice on strategic direction.
        </p>
        <p>
          Take a look in our <Link to="/portfolio">portfolio</Link> if you wish
          to understand more about what this might look like.
        </p>
        <p>
          There is no cost to this service for startups and so unfortunately we
          are unable to do this for everyone.
        </p>

        <br />
        <p class="text-center">
          <p class="text-center">
            <Link class="button" to="/application">
              Apply
            </Link>
          </p>
        </p>
      </div>

      <Footer />
    </div>
  );
}

export default Startups;
