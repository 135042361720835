import { useEffect } from "react";
import Footer from "./components/Footer";
import Banner from "./components/Banner";
import Header from "./components/Header";

function Careers() {
  useEffect(() => {
    document.title = "Claverton 42 - Careers";
  }, []);

  return (
    <div id="wrapper">
      <Header />

      <div id="content">
        <Banner />

        <h2>Careers</h2>

        <section>
          <div>
            <h2>Market Analyst</h2>
            <p>
              <span className="emphasis">Status: Closed</span>
            </p>
            <p>Location: Remote</p>
            <p>
              The Market Analyst will be responsible for collecting, analysing,
              and interpreting market data to provide actionable insights that
              inform strategic decisions. This role requires a keen analytical
              mind, proficiency in data analysis tools, and the ability to
              communicate complex findings in a clear and concise manner.
            </p>
          </div>
          <div>
            <p>
              Key Responsibilities:
              <ul>
                <li>
                  Conduct comprehensive market research to identify trends,
                  opportunities, and threats.
                </li>
                <li>
                  Analysis of industry reports, data, and consumer behaviour to
                  develop market insights.
                </li>
                <li>
                  Prepare detailed reports and presentations for senior
                  management and clients.
                </li>
                <li>
                  Collaborate with cross-functional teams to support strategic
                  initiatives.
                </li>
                <li>
                  Monitor and report on key performance indicators (KPIs)
                  relevant to market trends.
                </li>
                <li>
                  Use statistical tools and software for data analysis and
                  visualisation.
                </li>
                <li>
                  Stay up-to-date with industry developments and emerging
                  trends.
                </li>
              </ul>
            </p>
          </div>
        </section>

        <section>
          <div>
            <h2>Strategic Consultant</h2>
            <p>
              <span className="emphasis">Status: Closed</span>
            </p>
            <p>Location: Remote</p>
            <p>
              The Strategic Consultant will provide expert advice and insights
              to help clients achieve their business objectives. This role
              involves analysing complex business problems, developing strategic
              plans, and working closely with clients to implement solutions
              that drive growth and efficiency.
            </p>
          </div>
          <div>
            <p>
              Key Responsibilities:
              <ul>
                <li>
                  Conduct thorough business analysis to identify challenges and
                  opportunities.
                </li>
                <li>
                  Develop and present strategic plans to clients, outlining
                  actionable recommendations. Collaborate with clients to
                  implement strategic initiatives and track progress.
                </li>
                <li>
                  Perform competitive analysis and benchmark studies to inform
                  strategic decisions. Facilitate workshops and training
                  sessions for client teams.
                </li>
                <li>
                  Produce high-quality reports, presentations, and other
                  documentation.
                </li>
                <li>
                  Maintain strong relationships with clients, ensuring
                  satisfaction and continued collaboration. Stay informed about
                  industry trends, best practices, and emerging technologies.
                </li>
              </ul>
            </p>
          </div>
        </section>
        <section>
          <div>
            <h2>Digital Marketer</h2>
            <p>
              <span className="emphasis">Status: Closed</span>
            </p>
            <p>Location: Remote</p>
            <p>
              The Digital Marketer will develop and execute digital marketing
              campaigns to promote the company and client's services and enhance
              their online presence. This role requires expertise in digital
              marketing channels, data-driven decision-making, and content
              creation.
            </p>
          </div>
          <div>
            <p>
              Key Responsibilities:
              <ul>
                <li>
                  Plan and execute digital marketing campaigns across various
                  channels (e.g., SEO, SEM, social media, email marketing).
                </li>
                <li>
                  Create and manage content for the company’s website, blog, and
                  social media profiles. Analyse campaign performance and
                  provide insights and recommendations for improvement.
                </li>
                <li>
                  Conduct keyword research and optimize website content for
                  search engines. Manage online advertising budgets and track
                  ROI.
                </li>
                <li>
                  Collaborate with the marketing team to develop integrated
                  marketing strategies. Monitor industry trends and competitor
                  activities to identify new opportunities.
                </li>
                <li>
                  Use analytics tools to track and report on website traffic,
                  engagement, and conversions.
                </li>
              </ul>
            </p>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
}

export default Careers;
