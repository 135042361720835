import { useEffect } from "react";
import Footer from "./components/Footer";
import Banner from "./components/Banner";
import Header from "./components/Header";

function Reports() {
  useEffect(() => {
    document.title = "Claverton 42 - Reports";
  }, []);

  return (
    <div id="wrapper">
      <Header />

      <div id="content">
        <Banner />

        <h2>Reports</h2>

        <section>
          <div>
            <img
              src="/img/pexels-photo-7551783.avif"
              alt="The Science of Team Size"
            />
          </div>
          <div>
            <h2>The Science of Team Size</h2>
            <p>
              A look at what size teams should be and the sociology behind why
              that is.
            </p>
            <a href="/reports/the-science-of-team-size.pdf" target="_blank">
              Read the report.
            </a>
          </div>
        </section>

        <section>
          <div>
            <h2>Implementing remote and hybrid working</h2>
            <p>Key steps to a successful remote working culture.</p>
            <a
              href="/reports/implementing-remote-and-hybrid-working.pdf"
              target="_blank"
            >
              Read the report.
            </a>
            <br />
            <br />
            <a href="/reports/communication-policy.pdf" target="_blank">
              View an example communication policy.
            </a>
          </div>
          <div>
            <img
              src="/img/pexels-photo-4049991.avif"
              alt="Implementing Remote and Hybrid Working"
            />
          </div>
        </section>

        <section>
          <div>
            <img src="/img/pexels-photo-2283803.avif" alt="Why Strategy" />
          </div>
          <div>
            <h2>Why Strategy?</h2>
            <p>
              Outlining the critical importance of strategic planning for all
              companies, offering insights and practical recommendations to help
              businesses achieve their goals and ensure long-term success.
            </p>
            <a href="/reports/why-strategy.pdf" target="_blank">
              Read the report.
            </a>
          </div>
        </section>

        <section>
          <div>
            <h2>
              Behavioural Techniques and Human Psychology in Digital Marketing
            </h2>
            <p>Ten case studies to ponder.</p>
            <a href="/reports/behavioural-techniques.pdf" target="_blank">
              Read the report.
            </a>
          </div>
          <div>
            <img
              src="/img/pexels-photo-3148937.avif"
              alt="Behavioural Techniques and Human Psychology in Digital Marketing"
            />
          </div>
        </section>

        <section>
          <div>
            <img
              src="/img/pexels-photo-212286.avif"
              alt="Business Strategy Trends 2024"
            />
          </div>
          <div>
            <h2>Business Strategy Trends 2024</h2>
            <p>Discussion of three key trends to watch out for in 2024.</p>
            <a href="/reports/business-strategy-trends.pdf" target="_blank">
              Read the report.
            </a>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
}

export default Reports;
